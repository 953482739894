const initState = {
  connection: false,
  notification: {
    status: false,
    type: 'error', // error //warning // info // success
    message: '',
  },
};

export default initState;
