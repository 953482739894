import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  Button,
  Divider,
  Box,
  ButtonGroup,
} from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { applicationActions } from '../../../../application/actions/application';

function ModalNotificacion() {
  const dispatch = useDispatch();

  /* VARIABLES DE APLICACION */
  const { status, message, type } = useSelector((store) => store.bazooit.application.notification);

  /* VARIABLES DE INTERFAZ */
  const [titulo, setTitulo] = useState('');

  useEffect(() => {
    type === 'error' && setTitulo('Ha ocurrido un error.');
    type === 'warning' && setTitulo('¡Advertencia!');
    type === 'info' && setTitulo('Atención');
    type === 'success' && setTitulo('¡Listo!');
  }, [type]);

  const handleSalir = () => {
    dispatch(applicationActions.aplicationErrorReSet());
  };

  return (
    <>
      {/* MODAL LINK ARTEFACTO */}
      <Dialog disableEscapeKeyDown open={status} onClose={() => handleSalir()}>
        <DialogTitle>{titulo}</DialogTitle>
        <Divider />
        <DialogContent dividers>
          <Typography variant="p" component="p">
            <Box sx={{ mb: 0, mt: 2 }}>
              <>
                <Alert severity={type}>
                  <AlertTitle>{message}</AlertTitle>
                </Alert>
              </>

            </Box>
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ m: 1 }}>
          <Box sx={{ mb: 0, mt: 0 }}>
            <FormControl fullWidth>
              <ButtonGroup sx={{ pt: 0 }} fullWidth variant="contained">
                <Button color={type} onClick={() => handleSalir()}>
                  oK
                </Button>
              </ButtonGroup>
            </FormControl>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ModalNotificacion;
