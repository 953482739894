import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Divider,
  Box,
  ButtonGroup,
  CardContent,
  Alert,
  Card,
  CardHeader,
  CardActions,
  Grid,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Avatar from '@mui/material/Avatar';
import {
  red,
} from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ComentsView from '../ComentsView';
import FieldDowload from '../fieldDowload';
import CreateForms from '../../organismos/forms';
import { cardsActionsSaga } from '../../../../infrastructure/services/cards';

function ArtefactoMostrar({
  artefactoMostar,
  columIdMostar,
  artefactoMostarData,
  handleCloseMostrar,
  idWorkflowSelected,
  cardSelected,
}) {
  const dispatch = useDispatch();
  const [visibility, setVisibility] = useState([]);
  const [newFields, setNewFields] = useState(null);
  const [enabledForm, setEnabledForm] = useState(null);
  const [idActivitySelected, setIdActivitySelected] = useState(null);

  const findData = () => {
    if (artefactoMostarData) {
      const query = `${artefactoMostarData}/?columnId=${columIdMostar}`;
      dispatch(cardsActionsSaga.activeCardsGetDetail({ query }));
    }
  };

  useEffect(() => {
    findData();
  }, [artefactoMostarData]);

  const { cardDetail, result, connection } = useSelector((store) => store.bazooit.cards.cardDetail);
  const { cardHasBeenUpdated } = useSelector((store) => store.bazooit.cards);

  useEffect(() => {
    if (cardDetail) {
      const newArray = Array(cardDetail.fields.length).fill(0).map(() => true);
      setVisibility(newArray);
    }
  }, [cardDetail]);

  const rojo = red[500];

  const getFields = (get) => {
    console.log(get);
    setNewFields(get);
  };

  const getEnabledForm = (flag) => {
    setEnabledForm(flag);
  };
  const buscarDatos = (itemGroup) => {
    const buildetail = itemGroup != null && itemGroup.groups.map((group) => (
      <Box key={group.title}>
        <Typography sx={{ pl: 0 }} variant="h6" color="text.secondary">
          <strong>{group.title}</strong>
        </Typography>
        {
          group.fields.map((field) => (
            <Typography key={field.id} sx={{ pl: 0 }} variant="body2" color="text.secondary">
              <strong>{field.label}</strong>
              :
              {field.kind === 'file' && <FieldDowload value={field.value} url={field.url} />}
              {field.kind === 'list' && field.options.map((item) => item.id === field.value && item.label)}
              {field.kind !== 'file' && field.kind !== 'list' && field.value}
            </Typography>
          ))
        }
        <br />
      </Box>
    ));

    return buildetail;
  };

  const handlerChabgeVisibility = (indexItemGorup) => {
    const temp = visibility.map((c, i) => {
      if (i === indexItemGorup) {
        return !c;
      }
      return true;
    });
    setVisibility(temp);
  };

  const handlerActualizar = () => {
    let data = {};
    for (const x in newFields) {
      if (newFields[x].id && newFields[x].id === idActivitySelected) {
        for (const a in newFields[x].groups) {
          for (const b in newFields[x].groups[a].fields) {
            if (newFields[x].groups[a].fields[b].kind !== 'file') {
              data = { ...data, [newFields[x].groups[a].fields[b].id]: newFields[x].groups[a].fields[b].value };
            } else {
              data = { ...data, [`FILEBAZOOIT_${newFields[x].groups[a].fields[b].id}`]: newFields[x].groups[a].fields[b].file };
            }
          }
        }
      }
    }
    dispatch(cardsActionsSaga.activeCardsUpdateData(
      {
        cards: {
          workflowId: idWorkflowSelected,
          columnId: columIdMostar,
          activityId: idActivitySelected,
          ...data,
        },
        idCard: artefactoMostarData,
      },
    ));
    handleCloseMostrar();
  };

  const handlerCheckEdit = (itemGroup) => {
    setIdActivitySelected(itemGroup.id ? itemGroup.id : null);
    dispatch(cardsActionsSaga.activeCardHasBeenUpdated({
      cardId: artefactoMostarData,
      updatedAt: cardDetail.updatedAt,
    }));
  };
  useEffect(() => {
    if (cardHasBeenUpdated.result) { handlerActualizar(); }
  }, [cardHasBeenUpdated]);

  return (
    <Dialog
      disableEscapeKeyDown
      open={artefactoMostar}
      onClose={handleCloseMostrar}
      fullWidth="md"
      maxWidth="md"
      PaperProps={{
        style: {
          minHeight: '90%',
          maxHeight: '90%',
        },
      }}
    >
      <DialogTitle sx={{ lineHeight: 1.2 }}>
        <CardHeader
          avatar={(
            <Avatar
              sx={{
                bgcolor: rojo, width: 30, height: 30, fontSize: '15px',
              }}
              aria-label="recipe"
            >
              {cardSelected.visibleFieldsValues[0][0].toUpperCase()}

            </Avatar>
            )}

          title={cardSelected.visibleFieldsValues[0]}
          sx={{ p: 0, pb: 0 }}
        />
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => handleCloseMostrar()}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Divider />
      {' '}
      {cardHasBeenUpdated.checking && !cardHasBeenUpdated.result && cardHasBeenUpdated.message !== ''
        ? (
          <>
            <DialogContent sx={{ pl: 0, pr: 0 }}>
              <Box sx={{ pl: 4, pr: 4 }}>
                <Alert severity="error">{cardHasBeenUpdated.message}</Alert>
              </Box>
            </DialogContent>
            <CardActions>
              <ButtonGroup sx={{ pt: 0, zIndex: 5 }} fullWidth variant="contained">
                <Button onClick={(e) => { handlerActualizar(e); }}>
                  Si, Confirmar
                </Button>
                <Button onClick={(e) => { handleCloseMostrar(e); }}>
                  Descartar Cambios
                </Button>
              </ButtonGroup>
            </CardActions>
          </>
        ) : (
          <DialogContent sx={{
            p: 0, backgroundColor: '#ededed', display: 'flex',
          }}
          >
            <Grid
              container
              spacing={0}
              direction="row"
              sx={{
                height: 'auto',
                overflow: 'hidden',
                mb: 1,
              }}
            >
              <Grid item xs={12} sm={12} md={8}>
                <Box sx={{
                  p: 0.5,
                  overflow: 'hidden',
                  overflowY: 'auto',
                  height: 'inherit',
                }}
                >
                  {cardDetail && cardDetail.fields.map((itemGroup, indexItemGorup) => (
                    <Accordion key={itemGroup.name} defaultExpanded={itemGroup.initialFields}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        {itemGroup.name}
                      </AccordionSummary>
                      <AccordionDetails>
                        <>
                          {result && !visibility[indexItemGorup] && (
                            <Box sx={{ m: -2 }}>
                              <CreateForms
                                fields={[itemGroup]}
                                getFields={getFields}
                                getEnabledForm={getEnabledForm}
                                idGroupShow={itemGroup.id}
                              />
                            </Box>

                          )}
                          {result && visibility[indexItemGorup] && (
                          <Box sx={{ pr: 0, pl: 0 }}>
                            <Typography>
                              <Box sx={{ pb: 0 }}>
                                {buscarDatos(itemGroup)}
                              </Box>
                            </Typography>
                          </Box>

                          )}
                        </>
                      </AccordionDetails>
                      <AccordionActions>
                        {indexItemGorup > 0 && <Button onClick={() => handlerChabgeVisibility(indexItemGorup)}>{visibility[indexItemGorup] ? 'Editar' : 'Cancelar'}</Button>}
                        { !visibility[indexItemGorup]
                    && (
                    <Button color="success" disabled={!enabledForm} onClick={() => handlerCheckEdit(itemGroup)}>
                      Actualizar
                    </Button>
                    )}
                      </AccordionActions>
                    </Accordion>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Box sx={{
                  p: 0.5,
                  overflow: 'hidden',
                  overflowY: 'hidden',
                  height: '99%',
                }}
                >
                  {/* <Divider /> */}
                  <ComentsView
                    handlerShow={false}
                    show={false}
                    cardSelected={cardSelected}
                    integrate
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        )}
    </Dialog>

  );
}

export default ArtefactoMostrar;
