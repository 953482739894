import React from 'react';
import { NumericFormat } from 'react-number-format';
import {
  TextField,
} from '@mui/material';
import { PropTypes } from 'prop-types';

export function FieldNumber({
  field, onChange, indexField, indexGroup, indexFormulario, customize,
}) {
  const cleaningThisNumbre = (numberTrast) => {
    const numberClean = Number(numberTrast.replace(/[^.0-9]+/g, ''));
    return numberClean;
  };

  const materialUITextFieldProps = {
    fullWidth: true,
    size: 'small',
    label: field.label,
    value: field.value,
    FormHelperTextProps: {
      sx: {
        color: customize.HelperText.color,
        fontSize: customize.HelperText.size,
      },
    },
    inputProps: {
      step: 0.5,
    },
    // error:field.required && (field.value === '' || field.value === undefined || field.value === null)
    helperText: field.required && (field.value === '' || field.value === undefined || field.value === null) ? '* Campo Obligatorio' : '',
    onChange: (e) => { onChange(indexField, cleaningThisNumbre(e.target.value), indexGroup, indexFormulario); },
    sx: { mt: 2 },
  /*   inputProps: field.length && { maxLength: Number(field.length) }, */
  };
  return (
    <NumericFormat
      value={field.value}
      prefix={field.symbol}
      thousandSeparator
      isAllowed={(values) => {
        const { floatValue } = values;
        let flag = true;
        if (field.max > 0 && floatValue > field.max) { flag = false; }
        if (field.min < 0 && floatValue < field.min) { flag = false; }
        return flag;
      }}
      customInput={TextField}
      decimalScale={field.type === 'integer' ? 0 : 3}
      {...materialUITextFieldProps}
    />

  );
}

FieldNumber.propTypes = {
  field: PropTypes.objectOf.isRequired,
  onChange: PropTypes.func.isRequired,
  indexField: PropTypes.number.isRequired,
  indexGroup: PropTypes.number.isRequired,
  indexFormulario: PropTypes.number.isRequired,
  customize: PropTypes.objectOf.isRequired,
};

export default FieldNumber;
