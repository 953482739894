const entity = {
  INVALID_FORMAT: '***INVALID_FORMAT***',
  VALIDATIONS_MESSAGES: [
    '',
    '* Campo Obligatorio',
    '* Formato invalido',
  ],
};

export default entity;
