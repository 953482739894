import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  IconButton,
  Box,
  Select,
  MenuItem,
  ButtonGroup,
} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  red, blue, lightGreen,
} from '@mui/material/colors';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import LinearProgress from '@mui/material/LinearProgress';
import TimelineIcon from '@mui/icons-material/Timeline';
import DescriptionIcon from '@mui/icons-material/Description';
import './index.css';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { capitalizarPrimeraLetra } from '../../../../helpers.js';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import Crop75Icon from '@mui/icons-material/Crop75';
import { returnInitialsSrting } from '../../hooks/utils.ts'; 


type Props = {
  idEtapa: int;
  indexColumn?: int;
  actividades?: array;
  eventos?: array;
  handleActividad?: () => void;
  handleCambioEstado?: () => void;
  etapas?: array;
  buscar?: string;
  handleVerMostrar?: () => void;
  handleVerTimeLive?: () => void;
  templetes?: arr;
  handlerLoadFileTemplete?: () => void;
}

function Cards({
  idEtapa,
  indexColumn,
  actividades,
  eventos,
  handleActividad,
  handleCambioEstado,
  etapas,
  buscar,
  handleVerMostrar,
  handleVerTimeLive,
  templetes,
  handlerLoadFileTemplete,
  handlresComentsViewShow,
}: Props) {
  const dispatch = useDispatch();
  const columsCards = useSelector((store) => store.bazooit.cards.cards.cards.rows);
  const [localCrads, setlocalCrads] = useState([]);


  const { fields } = useSelector((store) => store.bazooit.cards.fieldsByColumnId);

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const rojo = red[500];
  const azul = blue[500];
  const verde = lightGreen[500];

  useEffect(() => {
    columsCards && setlocalCrads(columsCards);
  }, [columsCards]);

  const filtrar = (datos) => {
    let encontrado = buscar != '' ? false : true
    buscar != '' && !encontrado && datos.map((valorA, indexA) => {

      if (buscar != '' && valorA && !encontrado) {
        encontrado = valorA.includes(buscar);
      }
    });
    return encontrado;
  };

  const handleChange = ( card, activiy, idEtapa, idActividad ) => {
    handleActividad(card, activiy, idEtapa, idActividad);
  };

  const obtenerPorcentaje = (art) => {
    return 50;
    const actividadesCumplidasPorEatapa = [];
    for (let a = 0; actividades.length > a; ++a) {
      for (let b = 0; art.traza.actividad.ids.length > b; ++b) {
        if (actividades[a].id === art.traza.actividad.ids[b]) {
          actividadesCumplidasPorEatapa.push(actividades[a].id);
          break;
        }
      }
    }
    const porcentaje = actividadesCumplidasPorEatapa.length * 100 / actividades.length;
    return porcentaje;
  };

  const obtenerColeor = (art) => {
    return 50;

    const porcentaje = art.traza.actividad.ids.length * 100 / actividades.length;
    let colors = rojo;
    switch (true) {
      case porcentaje > 0 && porcentaje <= 33:
        colors = rojo;
        break;
      case porcentaje > 33 && porcentaje <= 66:
        colors = azul;
        break;
      case porcentaje > 66:
        colors = verde;
        break;
    }
    return colors;
  };

  const cardBuild = (card, actividadesEtapa) => {
    const art = (
      <Card sx={{ m: 0.5, mt: 0, mb: 0.8 }}>
        <CardHeader
          avatar={(
            <Avatar
              sx={{
                bgcolor: red[500], width: 30, height: 30, fontSize: '15px',
              }}
              aria-label="recipe"
            >
              {card.assignedToName && card.assignedToName!=='' && returnInitialsSrting(card.assignedToName).toUpperCase().slice(0,2)}
            </Avatar>
          )}
          action={(
            <FormControl
              size="small"
              className="opcionesArtefacto"
            >
              <ButtonGroup size="small" variant="contained" sx={{ boxShadow: 'none', p:1 }}>
                <Select
                  IconComponent={(props) => (<Crop75Icon {...props} />)}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={''}
                  onChange={(a) => handleChange(card, 'activiy', idEtapa, a.target.value)}
                >
                  {actividadesEtapa.map((item, punteroActividad) => (
                    <MenuItem key={punteroActividad} value={item.id} data-key={item} data-nombreActividad={item.name} >{capitalizarPrimeraLetra(item.name)}</MenuItem>

                  ))}
                </Select>
                <Select
                  IconComponent={(props) => (<ArrowForwardIcon sx={{ fontSize: '15px' }} {...props} />)}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value=""
                  onChange={(a, b) => handleCambioEstado(card, a.target.value)}
                  className='swiper-no-swiping'
                >
                  {etapas.map((itemEtapas, punteroEtapas) => (
                    indexColumn !== punteroEtapas && (
                      <MenuItem key={punteroEtapas} value={itemEtapas.id} data-nombreEtapa={itemEtapas.name}>
                        {capitalizarPrimeraLetra(itemEtapas.name)}
                        {indexColumn < punteroEtapas ? <ArrowForwardIcon /> : <ArrowBackIcon />}
                      </MenuItem>
                    )
                  ))}
                </Select>
              </ButtonGroup>
            </FormControl>
          )}
          title={card.visibleFieldsValues[0]}
          /* subheader={card.datos.datos[0].campos[0].nombreCampo} */
          subheader={(
            <>
              <Typography sx={{ p: 0, fontSize: 10 }} variant="body2" color="text.secondary">{card.visibleFieldsValues[1]}</Typography>
              <Typography sx={{ p: 0, fontSize: 10 }} variant="body2" color="text.secondary">{card.visibleFieldsValues[2]}</Typography>
            </>
          )}
          sx={{ p: 1, pb: 0 }}
        />
        <CardContent sx={{ m: 0, mt:1, p: 0, pb: '0px !important' }}>

          {/* < Typography sx={{ p: 2, pb: 0, pt: .5, pl:1 }} variant="body2" color="text.secondary">
                    <Chip size="small" avatar={<ArrowForwardIcon />} label={`${capitalizarPrimeraLetra(card.traza.etapa.glosaEtapa)}, ${moment(card.traza.etapa.fecha).format("DD-MM-YYYY HH:MM")}`} />
                </Typography>

                <Typography sx={{ p: 2, pb: 0, pt: .5, pl:1 }} variant="body2" color="text.secondary">
                    <Chip size="small" avatar={<Crop75Icon />} label={`${capitalizarPrimeraLetra(card.traza.actividad.glosaActividad)}, ${moment(card.traza.actividad.fecha).format("DD-MM-YYYY HH:MM")}`} />
                </Typography> */}
          <Stack
            sx={{
              p: 1, fontSize: 5, pb: 0.2, pl: 0.5, pt: 0.2,
            }}
            direction="row"
            spacing={0}
            alignItems="flex-start"
            className="stackBotoonCards"
          >
            <IconButton size="small" onClick={(e) => { e.stopPropagation(); /* handleVerMostrar(card.id, idEtapa);  */ handlresComentsViewShow(card, 'details', idEtapa); }} aria-label="share">
              <DescriptionIcon fontSize="10px" />
            </IconButton>
            {templetes && templetes.filter((x) => x.isDisabled === false).length > 0
              && (
                <Select
                  IconComponent={(props) => (<DocumentScannerIcon {...props} />)}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value=""
                  className="selectStackBotoonCards swiper-no-swiping"
                >

                  {templetes.map((itemTempletes, index) => (
                    !itemTempletes.isDisabled && (
                      <MenuItem onClick={(e) => { e.stopPropagation(); handlerLoadFileTemplete(card.id, itemTempletes.id, `${card.visibleFieldsValues[0]}_${itemTempletes.name.replaceAll(' ', '_')}`) }} key={index} value={itemTempletes.id} data-contenido={itemTempletes}>
                        {itemTempletes.name}
                      </MenuItem>
                    )
                  ))}
                </Select>
              )}
            {/*     <IconButton size="small" aria-label="share">
              <TimelineIcon fontSize="10px" onClick={(e) => { e.stopPropagation(); handleVerTimeLive(card, idEtapa); }} />
            </IconButton> */}

            <IconButton sx={{ ml: 'auto' }} size="small" onClick={(e) => { e.stopPropagation(); handlresComentsViewShow(card, 'coments'); }} aria-label="share">
              <InsertCommentIcon fontSize="10px" />
            </IconButton>

          </Stack>
        </CardContent>
        <LinearProgress sx={{ '& .MuiLinearProgress-bar1Determinate': { backgroundColor: obtenerColeor(card) } }} variant="determinate" value={obtenerPorcentaje(card)} />
      </Card>
    );
    return art;
  };

  const findCardForColums = (idFlujoEtapa, actividadesEtapa, eventosEtapa) => {
    const cards = localCrads[idFlujoEtapa] ? localCrads[idFlujoEtapa].map((card, index) => (
      filtrar(card.visibleFieldsValues) && cardBuild(card, actividadesEtapa)
    )) : null;
    return cards || null;
  };

  return (
    <Box sx={{ pb: 0.5 }}>
      {findCardForColums(idEtapa, actividades, eventos)}
    </Box>

  );
}

export default Cards;
