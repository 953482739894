import React, { useEffect, useState } from 'react';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { PropTypes } from 'prop-types';
import entity from '../entity';

export function FieldPhone({
  field, onChange, indexField, indexGroup, indexFormulario, customize,
}) {
  const [myState, setMyState] = useState('');
  useEffect(() => {
    field.value !== '' && field.value !== null && field.value !== undefined && setMyState(field.value);
  }, []);

  const handlerValidatePhone = () => {
    let validate = false;
    const country = field.validations[0];
    validate = myState !== '' ? matchIsValidTel(myState, { country }) : false;
    return validate;
  };

  const hadlerMessagge = () => {
    if (field.required && myState === '') {
      return entity.VALIDATIONS_MESSAGES[1];
    }
    if (field.required && myState !== '' && !handlerValidatePhone()) {
      return entity.VALIDATIONS_MESSAGES[2];
    }
    if (!field.required && myState !== '' && !handlerValidatePhone()) {
      return entity.VALIDATIONS_MESSAGES[2];
    }
    return entity.VALIDATIONS_MESSAGES[0];
  };

  useEffect(() => {
    if (myState !== '' && handlerValidatePhone()) {
      onChange(indexField, myState, indexGroup, indexFormulario);
    } else if (!field.required && myState !== '' && !handlerValidatePhone()) {
      onChange(indexField, entity.INVALID_FORMAT, indexGroup, indexFormulario);
    } else {
      onChange(indexField, '', indexGroup, indexFormulario);
    }
  }, [myState]);

  return (
    <MuiTelInput
      fullWidth
        // forceCallingCode
      value={myState}
      onChange={(e) => { setMyState(e); }}
      defaultCountry={field.validations.length > 0 && field.validations[0].length === 2 ? field.validations[0] : ''}
      onlyCountries={field.validations}
      size="small"
      FormHelperTextProps={{
        sx: {
          color: customize.HelperText.color,
          fontSize: customize.HelperText.size,
        },
      }}
      // error={field.required && !handlerValidatePhone(field.value, field.validations)}
      helperText={hadlerMessagge()}
      sx={{ mt: 2 }}
      disabled={field.hasOwnProperty('editable') ? !field.editable : false}
    />

  );
}

FieldPhone.propTypes = {
  field: PropTypes.objectOf.isRequired,
  onChange: PropTypes.func.isRequired,
  indexField: PropTypes.number.isRequired,
  indexGroup: PropTypes.number.isRequired,
  indexFormulario: PropTypes.number.isRequired,
  customize: PropTypes.objectOf.isRequired,
};

export default FieldPhone;
