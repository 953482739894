import React from 'react';
import { PropTypes } from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

export function FieldDateTime({
  field, onChange, indexField, indexGroup, indexFormulario, customize,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        label={field.label}
        inputFormat="DD/MM/YYYY"
        value={field.value === '' || field.value === undefined || field.value === null ? '' : field.value}
        onChange={(e) => onChange(indexField, moment(e).format('YYYY-MM-DD'), indexGroup, indexFormulario)}
        defaultValue={false}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            sx={{ mt: 2 }}
            size="small"
            value={field.label}
            inputProps={{ ...params.inputProps, readOnly: true }}
            FormHelperTextProps={{
              sx: {
                color: customize.HelperText.color,
                fontSize: customize.HelperText.size,
              },
            }}
            error={false}
            // error={field.required && (field.value === '' || field.value === undefined || field.value === null)}
            helperText={field.required && (field.value === '' || field.value === undefined || field.value === null) ? '* Campo Obligatorio' : ''}
          />
        )}
        orientation="portrait"
      />
    </LocalizationProvider>
  );
}

FieldDateTime.propTypes = {
  field: PropTypes.objectOf.isRequired,
  onChange: PropTypes.func.isRequired,
  indexField: PropTypes.number.isRequired,
  indexGroup: PropTypes.number.isRequired,
  customize: PropTypes.objectOf.isRequired,
};

export default FieldDateTime;
