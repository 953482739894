import React, { useEffect, useState } from 'react';
import {
  TextField,
} from '@mui/material';
import { PropTypes } from 'prop-types';
import { validateRut } from '@fdograph/rut-utilities';
import entity from '../entity';

export function FieldNumberId({
  field, onChange, indexField, indexGroup, indexFormulario, customize,
}) {
  const [myState, setMyState] = useState('');

  useEffect(() => {
    field.value !== '' && field.value !== null && field.value !== undefined && setMyState(field.value);
  }, []);

  const validateWithValidations = () => {
    let check = false;
    if (myState !== '' && field.validations[0] === 'CL') {
      check = validateRut(myState);
    }
    return check;
  };

  const formatNumberId = (numberIdImput) => {
    if (field.validations[0] === 'CL') {
      const reg = /[^0-9Kk]/g;
      const date = numberIdImput.toUpperCase().replaceAll(reg, '');
      setMyState(date);
    }
  };

  const hadlerMessagge = () => {
    if (field.required && myState === '') {
      return entity.VALIDATIONS_MESSAGES[1];
    }
    if (field.required && myState !== '' && !validateWithValidations()) {
      return entity.VALIDATIONS_MESSAGES[2];
    }
    if (!field.required && myState !== '' && !validateWithValidations()) {
      return entity.VALIDATIONS_MESSAGES[2];
    }
    return entity.VALIDATIONS_MESSAGES[0];
  };

  useEffect(() => {
    if (myState !== '' && validateWithValidations()) {
      onChange(indexField, myState, indexGroup, indexFormulario);
    } else if (!field.required && myState !== '' && !validateWithValidations()) {
      onChange(indexField, entity.INVALID_FORMAT, indexGroup, indexFormulario);
    } else {
      onChange(indexField, '', indexGroup, indexFormulario);
    }
  }, [myState]);

  return (
    <TextField
      fullWidth
      size="small"
      label={field.label}
      value={myState}
      FormHelperTextProps={{
        sx: {
          color: customize.HelperText.color,
          fontSize: customize.HelperText.size,
        },
      }}
      helperText={hadlerMessagge()}
      onChange={(e) => { formatNumberId(e.target.value); }}
      sx={{ mt: 2 }}
    />
  );
}

FieldNumberId.propTypes = {
  field: PropTypes.objectOf.isRequired,
  onChange: PropTypes.func.isRequired,
  indexField: PropTypes.number.isRequired,
  indexGroup: PropTypes.number.isRequired,
  indexFormulario: PropTypes.number.isRequired,
  customize: PropTypes.objectOf.isRequired,
};

export default FieldNumberId;
