import {
  spawn, call, put, takeLatest,
} from 'redux-saga/effects';
import { createAction } from '@reduxjs/toolkit';
import { flujoActions } from '../../application/actions/workFlow';
import { applicationActions } from '../../application/actions/application';

import api from '../api/index';

const apiRequest = api(process.env.REACT_APP_API_BAZOOIT);

export function* searchWorkflows(e) {
  yield put(flujoActions.connectionFlow({ connection: true }));
  try {
    const url = 'workflow/columnsByCompany';
    const response = yield call(
      apiRequest.get,
      url,
    );

    // Extraigo todos los datos
    const { data, message } = response;
    if (data) {
      yield put(flujoActions.createFlow({ data, message }));
    } else {
      const { type } = response;
      yield put(applicationActions.aplicationErrorSet({ status: true, message, type }));
    }
  } catch (err) {
    yield put(flujoActions.connectionFlow({ connection: false }));
    yield put(flujoActions.errorFlow({ message: err.message }));
    yield put(applicationActions.aplicationErrorSet({ status: true, message: err.message }));
  } finally {
  }
}

export function* createGrupoworkflows(e) {
  yield put(flujoActions.createGroupsFlowsConnection({ connection: true }));
  yield put(applicationActions.applicationConnection({ connection: true }));
  try {
    const params = e.payload;
    const url = 'flujo/grupo';
    const response = yield call(
      apiRequest.post,
      url,
      params,
    );

    // Extraigo todos los datos
    const { result, message } = response;

    if (result) {
      yield put(flujoActions.createGroupsFlows({ create: result }));
    } else {
      const { type } = response;
      yield put(applicationActions.aplicationErrorSet({ status: true, message, type }));
    }
  } catch (err) {
    yield put(flujoActions.createGroupsFlowsError({ message: err.message, error: err }));
    yield put(applicationActions.aplicationErrorSet({ status: true, message: err.message }));
  } finally {
    yield put(flujoActions.createGroupsFlowsConnection({ connection: false }));
    yield put(applicationActions.applicationConnection({ connection: false }));
  }
}

export function* createWorkflows(e) {
  yield put(flujoActions.createFlowsConnection({ connection: true }));
  yield put(applicationActions.applicationConnection({ connection: true }));
  try {
    const params = e.payload;
    const url = '/workflow/create';
    const response = yield call(
      apiRequest.post,
      url,
      params,
    );

    // Extraigo todos los datos
    const { success } = response;
    if (success) {
      yield put(flujoActions.createFlows({ create: success }));
    } else {
      const { message, type } = response;
      yield put(applicationActions.aplicationErrorSet({ status: true, message, type }));
    }
  } catch (err) {
    yield put(flujoActions.createFlowsError({ message: err.message, error: err }));
    yield put(applicationActions.aplicationErrorSet({ status: true, message: err.message }));
  } finally {
    yield put(flujoActions.createFlowsConnection({ connection: false }));
    yield put(applicationActions.applicationConnection({ connection: false }));
  }
}

export function* mofificarworkflows(e) {
  yield put(flujoActions.modificarFlujoConnection({ connection: true }));
  yield put(applicationActions.applicationConnection({ connection: true }));
  try {
    const params = e.payload;
    const url = 'flujo';
    const response = yield call(
      apiRequest.put,
      url,
      params,
    );

    // Extraigo todos los datos
    const { result } = response;
    if (result) {
      yield put(flujoActions.modificarFlujo({ modificado: result }));
    } else {
      const { message, type } = response;
      yield put(applicationActions.aplicationErrorSet({ status: true, message, type }));
    }
  } catch (err) {
    yield put(flujoActions.modificarFlujoError({ message: err.message, error: err }));
    yield put(applicationActions.aplicationErrorSet({ status: true, message: err.message }));
  } finally {
    yield put(flujoActions.modificarFlujoConnection({ connection: false }));
    yield put(applicationActions.applicationConnection({ connection: false }));
  }
}

export function* watchFetchFlujo() {
  yield takeLatest(workflowActionsSaga.activesearchWorkflows.type, searchWorkflows);
  yield takeLatest(workflowActionsSaga.activecreateWorkflows.type, createWorkflows);
  yield takeLatest(workflowActionsSaga.activecreateGrupoworkflows.type, createGrupoworkflows);
  yield takeLatest(workflowActionsSaga.activemodifyWorkflows.type, mofificarworkflows);
}

export default function* rootSaga() {
  yield spawn(watchFetchFlujo);
}

const activesearchWorkflows = createAction('workflowsaga/activesearchWorkflows');
const activecreateWorkflows = createAction('workflowsaga/activecreateWorkflows');
const activecreateGrupoworkflows = createAction('workflowsaga/activecreateGrupoworkflows');
const activemodifyWorkflows = createAction('workflowsaga/activemodifyWorkflows');

export const workflowActionsSaga = {
  activesearchWorkflows,
  activecreateWorkflows,
  activecreateGrupoworkflows,
  activemodifyWorkflows,
};
