import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import InitSession from '../components/templates/InitSession';
import Dashboard from '../components/templates/dashboard';
import { workflowActionsSaga } from '../../infrastructure/services/workFlow';
import { sesionActions } from '../../application/actions/session';
import { storageBrowserActions } from '../../application/actions/storageBrowser';
import ModalNotificacion from '../components/molecula/modalNotificacion';

// Containers
// import { Helmet } from 'react-helmet';

function App() {
  const dispatch = useDispatch();

  const validaTeSesion = () => {
    const data = sessionStorage.getItem('sesion');
    data !== null && dispatch(sesionActions.createSession(JSON.parse(data)));

    const dataUbicacion = JSON.parse(localStorage.getItem('ubicacion'));
    dataUbicacion != null && dispatch(storageBrowserActions.update(dataUbicacion));
  };
  validaTeSesion();

  const TOKEN = useSelector((store) => store.bazooit.session.token);

  useEffect(() => {
    TOKEN && dispatch(workflowActionsSaga.activesearchWorkflows());
  }, [TOKEN]);

  return (
    <>
      <ModalNotificacion />
      <BrowserRouter>
        <Switch>
          {TOKEN === null ? (
            <>
              <Route exact path="/">
                <InitSession />
              </Route>
              <Route exact path="/recoverPassword*">
                <InitSession recovery />
              </Route>
            </>
          ) : (
            <Route exact path="/*">
              <Dashboard />
            </Route>
          )}
        </Switch>
      </BrowserRouter>
    </>

  );
}

export default App;
