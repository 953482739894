import {
  spawn, call, put, takeLatest,
} from 'redux-saga/effects';
import { createAction } from '@reduxjs/toolkit';
import { applicationActions } from '../../application/actions/application';
import { templatesActions } from '../../application/actions/template';

import api from '../api/index';

const apiRequest = api(process.env.REACT_APP_API_BAZOOIT);

export function* getTemplate(e) {
  yield put(applicationActions.applicationConnection({ connection: true }));
  yield put(templatesActions.getTemplate({ conexion: true }));
  try {
    const { id } = e.payload;
    const url = `workflow/templatesByWorkflowId/${id}`;
    const response = yield call(
      apiRequest.get,
      url,
    );

    // Extraigo todos los datos
    const { success, data } = response;
    if (success) {
      yield put(templatesActions.getTemplate({ result: success, data: data.rows }));
    } else {
      const { message, type } = response;
      yield put(applicationActions.aplicationErrorSet({ status: true, message, type }));
    }
  } catch (err) {
    yield put(templatesActions.getTemplate({ error: true, message: 'Error el sel servicio de documento' }));
    yield put(applicationActions.aplicationErrorSet({ status: true, message: err.message }));
  } finally {
    yield put(templatesActions.getTemplate({ conexion: false }));
    yield put(applicationActions.applicationConnection({ connection: false }));
  }
}

export function* verifyTemplate(e) {
  yield put(applicationActions.applicationConnection({ connection: true }));
  yield put(templatesActions.verifyTemplate({
    conexion: true, error: false, message: '', fields: [], result: false,
  }));

  try {
    const formdata = new FormData();
    const params = e.payload.cards;
    const keys = Object.keys(params);
    const fileIds = [];

    for (const k of keys) {
      if (typeof (params[k]) === 'string') {
        formdata.append(`${k}`, `${params[k]}`);
      } else if (k === 'file_1' && params[k] !== null) {
        formdata.append('files', params[k]);
        fileIds.push(k);
      } else if (params[k] !== null) {
        formdata.append(`${k}`, params[k]);
      }
    }

    if (fileIds.length > 0) formdata.append('fileIds', `${JSON.stringify(fileIds)}`);

    const url = '/workflow/fieldsForTemplateFile';
    const response = yield call(
      apiRequest.postFormData,
      url,
      formdata,
    );

    // Extraigo todos los datos
    const { success } = response;

    if (success) {
      const { data } = response;
      const { rows } = data;
      yield put(templatesActions.verifyTemplate({ fields: rows, result: success }));
    } else {
      const { message } = response;
      yield put(templatesActions.verifyTemplate({
        error: !success, message, fields: [], result: false,
      }));
    }
  } catch (err) {
    yield put(templatesActions.verifyTemplate({
      error: true, message: 'Error al cargar el archivo', fields: [], result: false,
    }));
  } finally {
    yield put(templatesActions.verifyTemplate({ conexion: false }));
    yield put(applicationActions.applicationConnection({ connection: false }));
  }
}

export function* createTemplate(e) {
  yield put(applicationActions.applicationConnection({ connection: true }));
  yield put(templatesActions.createTemplate({ conexion: true }));
  try {
    const formdata = new FormData();
    const params = e.payload.cards;
    const keys = Object.keys(params);
    const fileIds = [];

    for (const k of keys) {
      if (typeof (params[k]) === 'string') {
        formdata.append(`${k}`, `${params[k]}`);
      } else if (k === 'file_1' && params[k] != null) {
        formdata.append('files', params[k]);
        fileIds.push(k);
      } else if (params[k] != null) {
        formdata.append(`${k}`, params[k]);
      }
    }

    if (fileIds.length > 0) formdata.append('fileIds', `${JSON.stringify(fileIds)}`);

    const url = '/workflow/createTemplate';
    const response = yield call(
      apiRequest.postFormData,
      url,
      formdata,
    );

    // Extraigo todos los datos
    const { success } = response;
    if (success) {
      yield put(templatesActions.createTemplate({ result: success }));
    } else {
      const { message } = response;
      yield put(templatesActions.createTemplate({ error: !success, message }));
    }
  } catch (err) {
    yield put(templatesActions.createTemplate({ error: true, message: 'Error el sel servicio de plantilla' }));
  } finally {
    yield put(templatesActions.createTemplate({ conexion: false }));
    yield put(applicationActions.applicationConnection({ connection: false }));
  }
}

export function* updateSatatusTemplate(e) {
  yield put(applicationActions.applicationConnection({ connection: true }));
  yield put(templatesActions.updateTemplate({ conexion: true }));
  try {
    const parmans = e.payload;
    const url = `workflow/template/disable/${parmans.workflowId}/${parmans.templateId}`;
    const response = yield call(
      apiRequest.put,
      url,
      null,
    );

    // Extraigo todos los datos
    const { success } = response;
    if (success) {
      yield put(templatesActions.updateTemplate({ result: success, conexion: false }));
    } else {
      const { message, type } = response;
      yield put(applicationActions.aplicationErrorSet({ status: true, message, type }));
    }
  } catch (err) {
    yield put(templatesActions.updateTemplate({ error: true, message: 'Error el sel servicio de documento' }));
    yield put(applicationActions.aplicationErrorSet({ status: true, message: err.message }));
  } finally {
    yield put(templatesActions.updateTemplate({ conexion: false }));
    yield put(applicationActions.applicationConnection({ connection: false }));
  }
}

export function* deleteTemplate(e) {
  yield put(applicationActions.applicationConnection({ connection: true }));
  yield put(templatesActions.deleteTemplate({ conexion: true }));
  try {
    const parmans = e.payload;
    const url = `workflow/template/${parmans.workflowId}/${parmans.templateId}`;
    const response = yield call(
      apiRequest.delete,
      url,
    );

    // Extraigo todos los datos
    const { success } = response;
    if (success) {
      yield put(templatesActions.deleteTemplate({ result: success, conexion: false }));
    } else {
      const { message, type } = response;
      yield put(applicationActions.aplicationErrorSet({ status: true, message, type }));
    }
  } catch (err) {
    yield put(templatesActions.deleteTemplate({ error: true, message: 'Error el sel servicio de documento' }));
    yield put(applicationActions.aplicationErrorSet({ status: true, message: err.message }));
  } finally {
    yield put(templatesActions.deleteTemplate({ conexion: false }));
    yield put(applicationActions.applicationConnection({ connection: false }));
  }
}

export function* downloadTemplateFile(e) {
  yield put(applicationActions.applicationConnection({ connection: true }));
  try {
    const parmans = e.payload;
    const url = 'cards/generateDocument';
    const response = yield call(
      apiRequest.postDownLoadFile,
      url,
      parmans,
    );
  } finally {
    yield put(applicationActions.applicationConnection({ connection: false }));
  }
}

export function* watchDocumentoPlantillas() {
  yield takeLatest(TemplatesService.activeGetTemplate.type, getTemplate);
  yield takeLatest(TemplatesService.activeVerifyTemplate.type, verifyTemplate);
  yield takeLatest(TemplatesService.activeCreateTemplate.type, createTemplate);
  yield takeLatest(TemplatesService.activeDeleteTemplate.type, deleteTemplate);
  yield takeLatest(TemplatesService.activeupdateSatatusTemplate.type, updateSatatusTemplate);
  yield takeLatest(TemplatesService.activeDownloadTemplateFile.type, downloadTemplateFile);
}

export default function* rootSaga() {
  yield spawn(watchDocumentoPlantillas);
}

const activeGetTemplate = createAction('templateSaga/activeGetTemplate');
const activeVerifyTemplate = createAction('templateSaga/activeVerifyTemplate');
const activeCreateTemplate = createAction('templateSaga/activeCreateTemplate');
const activeDeleteTemplate = createAction('templateSaga/activeDeleteTemplate');
const activeupdateSatatusTemplate = createAction('templateSaga/updateSatatusTemplate');
const activeDownloadTemplateFile = createAction('templateSaga/downloadTemplateFile');

export const TemplatesService = {
  activeGetTemplate,
  activeVerifyTemplate,
  activeCreateTemplate,
  activeDeleteTemplate,
  activeupdateSatatusTemplate,
  activeDownloadTemplateFile,
};
