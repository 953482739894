import { createSlice } from '@reduxjs/toolkit';
import initState from '../../domain/entities/application';

function applicationConnection(state, action) {
  state.connection = action.payload.connection;
}

// Just Errors
function aplicationErrorSet(state, action) {
  state.notification.status = action.payload.status;
  action.payload.hasOwnProperty('type') && action.payload.type !== undefined && (state.notification.type = action.payload.type);
  state.notification.message = action.payload.message;
}

function aplicationErrorReSet(state, action) {
  state.notification.status = false;
  state.notification.type = 'error';
  state.notification.message = '';
}

const applicationReducer = createSlice({
  name: 'application',
  initialState: initState,
  reducers: {
    applicationConnection,
    aplicationErrorSet,
    aplicationErrorReSet,

  },
});

export const applicationActions = {
  ...applicationReducer.actions,
};

export default applicationReducer.reducer;
