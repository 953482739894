import {
  spawn, call, put, takeLatest,
} from 'redux-saga/effects';
import { createAction } from '@reduxjs/toolkit';
import { comentsActions } from '../../application/actions/coments';
import { applicationActions } from '../../application/actions/application';

import api from '../api/index';

const apiRequest = api(process.env.REACT_APP_API_BAZOOIT);

export function* createComentsFetch(e) {
  yield put(applicationActions.applicationConnection({ connection: true }));
  yield put(comentsActions.createComentsActions({ connection: true }));
  try {
    const params = e.payload.message;
    const url = '/cardComments/create';
    const response = yield call(
      apiRequest.post,
      url,
      params,
    );

    // Extraigo todos los datos
    const { success } = response;
    if (success) {
      yield put(comentsActions.createComentsActions({ result: success }));
    } else {
      const { message, type } = response;
      yield put(comentsActions.createComentsActions({ result: success, message }));
      yield put(applicationActions.aplicationErrorSet({ status: true, message, type }));
    }
  } catch (err) {
    yield put(comentsActions.createComentsActions({ error: true, result: false }));
    yield put(applicationActions.aplicationErrorSet({ status: true, message: err.message }));
  } finally {
    yield put(applicationActions.applicationConnection({ connection: false }));
    yield put(comentsActions.createComentsActions({ connection: false }));
  }
}

export function* getComentsFetch(e) {
  yield put(applicationActions.applicationConnection({ connection: true }));
  yield put(comentsActions.getComentsActions({ connection: true, coments: [] }));
  try {
    const { cardId } = e.payload;
    const url = `cardComments/${cardId}`;
    const response = yield call(
      apiRequest.get,
      url,
      null,
    );

    // Extraigo todos los datos
    const { success, data } = response;
    if (success) {
      yield put(comentsActions.getComentsActions({ result: success, coments: data.rows }));
    } else {
      const { message, type } = response;
      yield put(comentsActions.getComentsActions({ result: success, message }));
      yield put(applicationActions.aplicationErrorSet({ status: true, message, type }));
    }
  } catch (err) {
    yield put(comentsActions.getComentsActions({ error: true, result: false }));
    yield put(applicationActions.aplicationErrorSet({ status: true, message: err.message }));
  } finally {
    yield put(applicationActions.applicationConnection({ connection: false }));
    yield put(comentsActions.getComentsActions({ connection: false }));
  }
}

export function* updateComentsFetch(e) {
  yield put(applicationActions.applicationConnection({ connection: true }));
  yield put(comentsActions.updateComentsActions({ connection: true }));
  try {
    const { comment, cardId, commentId } = e.payload.message;
    const url = `cardComments/${cardId}/${commentId}`;
    const response = yield call(
      apiRequest.patch,
      url,
      { comment },
    );

    // Extraigo todos los datos
    const { success, data } = response;
    if (success) {
      yield put(comentsActions.updateComentsActions({ result: success, data }));
    } else {
      const { message, type } = response;
      yield put(comentsActions.updateComentsActions({ result: success, message }));
      yield put(applicationActions.aplicationErrorSet({ status: true, message, type }));
    }
  } catch (err) {
    yield put(comentsActions.updateComentsActions({ error: true, result: false }));
    yield put(applicationActions.aplicationErrorSet({ status: true, message: err.message }));
  } finally {
    yield put(applicationActions.applicationConnection({ connection: false }));
    yield put(comentsActions.updateComentsActions({ connection: false }));
  }
}

export function* deletComentsFetch(e) {
  yield put(applicationActions.applicationConnection({ connection: true }));
  yield put(comentsActions.deleteComentsActions({ connection: true }));
  try {
    const { cardId, commentId } = e.payload.message;
    const url = `/cardComments/${cardId}/${commentId}`;
    const response = yield call(
      apiRequest.delete,
      url,
      null,
    );

    // Extraigo todos los datos
    const { success, data } = response;
    if (success) {
      yield put(comentsActions.deleteComentsActions({ result: success, data }));
    } else {
      const { message, type } = response;
      yield put(comentsActions.deleteComentsActions({ result: success, message }));
      yield put(applicationActions.aplicationErrorSet({ status: true, message, type }));
    }
  } catch (err) {
    yield put(comentsActions.deleteComentsActions({ error: true, result: false }));
    yield put(applicationActions.aplicationErrorSet({ status: true, message: err.message }));
  } finally {
    yield put(applicationActions.applicationConnection({ connection: false }));
    yield put(comentsActions.deleteComentsActions({ connection: false }));
  }
}

export function* watchFetchmaster() {
  yield takeLatest(comentsService.createComents.type, createComentsFetch);
  yield takeLatest(comentsService.getComents.type, getComentsFetch);
  yield takeLatest(comentsService.updateComents.type, updateComentsFetch);
  yield takeLatest(comentsService.deletComents.type, deletComentsFetch);
}

export default function* rootSaga() {
  yield spawn(watchFetchmaster);
}

const createComents = createAction('usuarioSaga/createComents');
const getComents = createAction('usuarioSaga/getComents');
const updateComents = createAction('usuarioSaga/updateComents');
const deletComents = createAction('usuarioSaga/deletComents');

export const comentsService = {
  createComents,
  getComents,
  updateComents,
  deletComents,
};
